@import "./theme.scss";
 
*,
*::before,
*::after {
 margin: 0;
 padding: 0;
 box-sizing: inherit;
}

html {  
  font-size: 62.5%; // 1rem = 10px 
}

 body {
  position: relative;
  background-color: $primary-color;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
}

// ------------------ APP Layouts ----------------------------------

// Desktop grid layout > 800px (50em)
.App {
  height: 100vh;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: 10vh 80vh 10vh;

  grid-template-areas: "nav nav social"
                        "page page page"
                        "page page page";

 

}


// Mobile and Tablet grid layout | 50em == 800px
// Styles apply upto and including 800px wide viewport
// Grid def include social links at bottom - move to top on large screens.
@media only screen and (max-width: 50em) {
    .App {
      grid-template-areas: "nav nav nav"
                            "page page page"
                            "social social social"
    } 
}

//-----------------------------------------------------------


// Global styles 
a {
  color: inherit;
  text-decoration: none;
}

.page {
  opacity: 0;
  grid-area: page;
}

@keyframes fadeIn {
    from {
      opacity: 0; 
    }
    to {
      opacity: 1;
    }
}
  
.fade-in {
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; 
  animation-delay: .75s;
}

.scale-icon {
  display: block;
  transform: scale(1);
  transition: all .2s;
  cursor: pointer;
  &:hover {
      transform: scale(1.15);
  }
  &:active{
      transform: scale(1);
  }
}

.animated-btn {
  border-radius: 3px;
  box-shadow: 0 3px 5px rgba(0,0,0,.6);
  transform: scale(1) translate(0,0);
  transition: .3s all;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;

  &:hover {
      transform: scale(1.1) translate(0,0);
  }

  &:active {
      transform: scale(1) translate(5px, -5px);
      box-shadow: none;
  }
}
