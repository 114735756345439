@import '../../theme.scss';

.about {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 36% 64%;
        overflow: hidden;
}

.intro {

    padding-top: 9%;
    padding-left: 10%;

    &__greeting {
        font-size: 3.5rem;
    }

    &__name {
        font-size: 5.5rem;
    }
   
    &__role {

        font-size: 4.75rem;
        font-weight: 300;
        & > span {
            display: block;
        }
        &--1 {
            padding-left: 3.5rem;
        }

        &--2 {
            padding-left: 7rem;
        }
    }


    &__resume {
        margin-top: 5rem;
        background-color: $accent-orange;
        border-color: $accent-orange;
        color: $primary-color;
        text-align: center;
        padding: 1rem;
        font-size: 3rem;
    }

}

@media only screen and (min-width: calc(50em + 1px)) {
    
    .headshot {
        display: flex;
        justify-content: flex-end;
        &__img {
            width: 53rem;
        }
    }
}


@media only screen and (max-width: 50em) {

    .intro {

        padding-top: 8vh;
        padding-left: 10vw;

        @media only screen and (min-width: 37.5em) {
            padding-top: 2vh;
        }

        &__container {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            & > * {
                margin: .70vw 0;
            }
        }
    
        &__greeting {
            font-size: 4.5vw;
        }

        &__name {
            font-size: 7vw;
        }

        &__role {
            font-size: 5vw;
            color: $accent-blue;
            &--1 {
                padding-left: 4.75vw;
            }

            &--2 {
                padding-left: 7vw;
            }
        }
    }

    .headshot {
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        &__container {
            position: relative;
            width: 80vw;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        &__img {           
            display: block; 
            max-height: 100%;
            max-width: 100%;
            position: absolute; 
            bottom: 0; 
            left: 50%; 
            transform: translate(-50%); 
            z-index: -1;
        }
    }
}


    





