@import '../../../theme.scss';
.email {

    &__result {
        position: absolute;
        padding: 3rem;
        width: 100%;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid $accent-orange;
        border-radius: 5px;
        z-index: 1;
        background-color: $primary-color;
        box-shadow: 3px 5px 5px rgba(0,0,0,.6);

        & h1 {
            color: $accent-blue;
            font-size: 3rem;
            letter-spacing: 2px;
            text-align: center;
            margin-bottom: 2rem;
        }

        & p {
            color: $accent-blue;
            font-size: 2rem;
            margin: 1rem 0;
        }

    }
}

@media only screen and (max-width: 70em) {

    .email {
        &__result {
            width: 80%;
        }
    }
}

@media only screen and (max-width: 50em) {

    .email {
        &__result {

            padding: 4vw;

            & h1 {
                font-size: 6vw;
            }

            & p {
                font-size: 4vw;
                margin: 2vw 0;
            }
        }
    }
}