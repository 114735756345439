.project-detail {

    padding: 2rem;

    &__text-container {
        width: 80%;
        margin: 0 auto;
        display: flex;
    }

    &__description {
        font-size: 2rem;
        padding: 2rem;
    }

    &__tools {
        padding: 2rem;

        & > h2 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }

        & > ul {
            list-style: none;
            display: flex;

            & li {
                margin: 1rem;
                font-size: 2rem;
            }
        }
    }

}

@media only screen and (max-width: 50em) {

    .project-detail {

        &__text-container {
            padding: 1rem;
            width: 100%;
            flex-direction: column;
        }

        &__description {
            font-size: 5vw;
        }

        &__tools {

            & > h2 {
                font-size: 6vw;
            }

            & > ul {
                & li {
                    margin: 1rem;
                    font-size: 5vw;
                }
            }
        }
    }
}

