.music {

    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    padding-top: 15rem;

    &__headphones-container {
        display: flex;
        justify-content: center;
    }

    &__headphones {
        height: 7rem;
        width: 7rem;
        margin-bottom: 8rem;
    }

    &__notes-container {
        display: flex;
        justify-content: center;
    }

    &__notes {
        height: 7rem;
        width: 7rem;
    }

    &__coming-soon {
        margin-top: 5rem;
        font-size: 4rem;
    }
}


@media only screen and (max-width: 50em) {
    .music {

        padding-top: 18vw;

        &__notes {
            height: 7vw;
            width: 7vw;
        }

        &__headphones {
            height: 10vw;
            width: 10vw;
            margin-bottom: 10vw;
        }

        &__coming-soon {
            margin-top: 5rem;
            font-size: 8vw;
        }
    }
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.pulse {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes note1 {
    0% {
        transform: translateY(0);
    } 25% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(5px);
    } 
    100% {
        transform: translateY(0);
    }
}

.note1 {
    animation-name: note1;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes note2 {
    0% {
        transform: translateY(10px);
    } 
    25% {
        transform: translateY(18px);
    }
    50% {
        transform: translateY(10px);
    }
    75% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(10px);
        
    } 
}

.note2 {
    animation-name: note2;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes note3 {
    0% {
        transform: translateY(-15px);
    } 
    20% {
        transform: translateY(-10px);
    }
    40% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(-8px);
    }
    100% {
        transform: translateY(-15px);
        
    } 
}

.note3 {
    animation-name: note3;
    animation-duration: 1.75s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}