@import '../../theme.scss';

.resume-dialog {
    opacity: 0;
    width: 30rem;
    position: absolute;
    padding: 2rem;
    border: 1px solid $accent-orange;
    background-color: $primary-color;
    border-radius: 5px;
    z-index: 2;
    box-shadow: 3px 5px 5px rgba(0,0,0,.6);

    &__message {
        font-size: 2rem;
        margin: 1rem;
    }

    &__btn-container {
        display:flex;
        justify-content: center;
    }

    &__btn {
        margin: 2rem 1rem;
        background-color: $accent-orange;
        border: none;
        color: $primary-color;
        text-align: center;
        width: 8rem;
        padding: 1rem;
        font-size: 2rem;
    }
}

@media only screen and (max-width: 50em) {

    .resume-dialog {
        
        transform: translate(-50%, -50%);
        width: 80vw;
    

        &__message {
            font-size: 6vw;
            margin: 2vw;
        }

        &__btn {
            margin: 2vw 1vw;
            width: 18vw;
            padding: 2vw;
            font-size: 6vw;
        }
    }

    .resume-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,.7);
        z-index: 1;
    }
}