@import '../../theme.scss';

.nav {
    grid-area: nav;

    &__links {
        display: block;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;

        & li {
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 .5rem;
        }
    }

    &__link {
       
        display: inline-block;
        color: $accent-orange;
        text-transform: uppercase;
        font-size: 3rem;
        letter-spacing: 3px;
        padding: 1rem 1.5rem;
        border-radius: 3px;
        margin: 1rem .5rem;
        transform: scale(1);
        transition: all .25s;

        &:last-child {
            margin-right:0
        }

        &:hover {
            background-color: $accent-orange;
            color: $primary-color;
            transform: scale(1.12);
        } 
    }
}

.active-nav-link {
    background-color: $accent-orange;
    color: $primary-color;
    transform: scale(1);

    &:hover {
        transform: scale(1);
    }
}

// Mobile and Tablet styles.
@media only screen and (max-width: 50em) {

    .nav {

        &__links {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            & li {
                display: block;
                margin: 0 
            }
        }

        &__link {
            color: $primary-color;
            background-color: $accent-orange;
            display: grid;
            margin: 0;
            padding: 0;
            height: 100%;
            font-size: 4vw;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            &:last-child {
                margin-right: 0
            }
            &:not(:last-child) {
                border-right: 1px solid black;
            }

            background-color: $accent-orange;

            background-image: 
                        linear-gradient(transparent 85%, darken($accent-orange, 10%) 95%, darken($accent-orange, 15%) 99%),
                        linear-gradient(to right, transparent 95%, darken($accent-orange, 10%) 98%, darken($accent-orange, 15%) 99%),
                        linear-gradient(to left, transparent 95%, darken($accent-orange, 10%) 98%, darken($accent-orange, 15%) 99%),
                        linear-gradient(to top, transparent 88%, darken($accent-orange, 10%) 95%, darken($accent-orange, 15%) 99%); 
 
            &:hover {
                background-color: $accent-orange;
                color: $primary-color;
                transform: scale(1);
            } 
        }
    }
}
