@import '../../theme.scss';

.email {

    overflow: scroll;

    &__form-wrapper {
        display:grid;
        grid-template-columns: 1fr 1fr;
        width: 85%;
        height: 100%;
        margin: 0 auto;
        padding: 2rem;
    }

    &__hero {
        background-image: url(../../../public/images/charlesdeluvio-AT5vuPoi8vc-unsplash.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &__form-container {
        position: relative;
        height: 100%;
        background-color: rgb(22,26,20);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &__form {

        display: flex;
        flex-direction: column;
        
        padding: 2rem 4rem;

        & > * {
            display: block;
            width: 100%;
        }

        & h1 {
            font-size: 4rem;
            letter-spacing: 1.2px;
            margin: 3rem 0;
            color: $primary-color;
        }

        & label {
            
            margin: 1rem 0;
            display: flex;

            & span {
                font-weight: bold;
                padding: 1rem;
                width: 10rem;
                font-size: 2rem;
                color: $primary-color;
                background-color: $accent-orange;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            
            & input {
                flex-grow: 1;
                font-size: 2rem;
                padding: 1rem;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        & textarea {
            height: 30rem;
            margin-top:1rem;
            padding: 1rem;
            font-size: 2rem;
            border-radius: 5px;
        }

        & button {
            margin: 1.5rem 0;
            width: fit-content;
            font-size: 2rem;
            padding: 1rem 1.5rem;
            align-self: flex-end;
            border: none;
            border-radius: 5px;
            background-color: $accent-orange;
            color: $primary-color;
        }
    }
}

@media only screen and (max-width: 70em) {

    .email {

        &__form-wrapper {
            width: 70%;
            grid-template-columns: 1fr;
            align-items: flex-end;
        }

        &__hero {
            background-color: rgb(27,31,25);
            background-position: center top  30%;
            border-radius: 5px;
            height: 100%;
            grid-column: 1 / 1;
            grid-row:  1 /  1;
        }

        &__form-container {
            background:none;
            height: 90%;
            grid-column: 1 / 1;
            grid-row:  1 /  1;
        }

        &__form {

            & input, & textarea {
                background-color: rgba(0,0,0,.7);
                color: $accent-blue;
                font-weight: bold;
            }

            & h1 {
                font-size: 3rem;
                margin: 0;
                margin-bottom: 2rem;
            }

            & textarea {
                height: 20rem;
            }
        }
    }
}


@media only screen and (max-width: 50em) {

    .email {

        &__form-wrapper {
            padding: 10px;
            width: 98vw;
            
        }

        &__form-container {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &__form {
            
            padding: 2vw 4vw;

            & h1 {
                font-size: 7vw;
                padding: 3vw 0;
                margin: 2vw 0;
                width: fit-content;
                background-color: rgba(22,26,20, .7);
            }

            & label {
            
                margin: 2vw 0;

                & span {
                    display: grid;
                    align-items: center;
                    width: 22vw;
                    font-size: 4.5vw;
                }
                
                & input {
                    font-size: 5vw;
                    padding: 1.5vw;
                }
            }

            & textarea {
                height: 60vw;
                margin-top:2vw;
                padding: 1.5vw;
                font-size: 5vw;
            }

            & button {
                margin: 4vw 0;
                width: fit-content;
                font-size: 5vw;
                padding: 2vw 2.5vw;
                border: none;
            }
        }
    }
}



